import { useState } from "react";
//  les input pour la modal education, pour eviter les conflit sur le label
export function Input({ name, label, type, value }) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <label
        className={`form-label floating-label ${isFocused && "focused"}  `}
        htmlFor={name}
      >
        {label}
      </label>

      {type === "textarea" ? (
        <textarea
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          name={name}
          id={name}
          className="form-control"
        >
          {value}
        </textarea>
      ) : (
        <input
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          name={name}
          type={type}
          value={value}
          id={name}
          className="form-control"
        />
      )}
    </>
  );
}
