import { useDispatch, useSelector } from "react-redux";
import { Buttons } from "../components/Comments/Buttons";
import { CommentCard } from "../components/Comments/Card";
import { useEffect, useState } from "react";
import { fetchDataComment } from "../api/commentSection";
import { setDataComment } from "../store/features/comments";
import { ModalContent } from "../components/Comments/Modal/ModalContent";

export function Comments() {
  const [defaultDataLocal, setDefaultDataLocal] = useState([]); // Stocker les données initiales localement
  const dispatch = useDispatch();

  // Récupérer les filtres depuis Redux
  const category = useSelector((state) => state.comment.categoryFilter);
  const isConfirmed = useSelector((state) => state.comment.confirmationFilter);

  // Charger les données initiales depuis l'API
  useEffect(() => {
    const request = async () => {
      const data = await fetchDataComment();
      if (data) {
        setDefaultDataLocal(data); // Stocker localement les données brutes
        dispatch(setDataComment(data)); // Initialiser Redux avec toutes les données
      }
    };

    request();
  }, [dispatch]);

  // Filtrer les données localement
  useEffect(() => {
    const filteredData = defaultDataLocal.filter((comment) => {
      const isCategoryMatch =
        category === "" || comment.article_id === category;

      const isConfirmationMatch =
        isConfirmed === "all" ||
        (isConfirmed === "confirmed" && comment.confirmed) ||
        (isConfirmed === "notConfirmed" && !comment.confirmed);

      return isCategoryMatch && isConfirmationMatch;
    });
    // Mettre à jour Redux uniquement lorsque les données filtrées changent
    dispatch(setDataComment(filteredData));
  }, [defaultDataLocal, category, isConfirmed, dispatch]);

  // Récupérer les données filtrées depuis Redux
  const data = useSelector((state) => state.comment.dataComment) || [];

  return (
    <>
      <div className="CommentsSection d-flex">
        <h2>Commentaires</h2>

        {/* Composant pour les boutons */}
        <Buttons />

        {/* Liste des commentaires */}
        <ul className="row cards gap-2">
          {data.map(
            (
              {
                article_id,
                articleTitle,
                name,
                email,
                comment,
                stars,
                created_at,
                confirmed,
              },
              index
            ) => (
              <CommentCard
                key={index}
                name={name}
                date={created_at}
                content={comment}
                articleTitle={articleTitle}
                stars={stars}
                userEmail={email}
                confirmed={confirmed}
              />
            )
          )}
        </ul>
      </div>
      <ModalContent />
    </>
  );
}
