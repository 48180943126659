import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';

// Enregistre tous les éléments requis pour un graphique en ligne
Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale);

export function chart(element) {
    const myChart = new Chart(element, {
      type: "line",
      data: {
        labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin"],
        datasets: [
          {
            label: "Nombre de vues",
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: "rgba(0, 192, 255, 0.2)",
            borderColor: "rgba(0, 192, 255, 1)",
            borderWidth: 2,
            pointBackgroundColor: "rgba(255, 255, 255, 1)",
            pointBorderColor: "rgba(0, 192, 255, 1)",
            pointHoverBackgroundColor: "rgba(0, 192, 255, 1)",
            pointHoverBorderColor: "rgba(255, 255, 255, 1)",
            tension: 0.4,
          },
        ],
      },
      options: {
        scales: {
          x: {
            grid: {
              color: "rgba(255, 255, 255, 0.3)",
            },
            border: {
              color: "#fff",
            },
            ticks: {
              color: "#fff",
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              color: "rgba(255, 255, 255, 0.3)",
            },
            border: {
              color: "#fff",
            },
            ticks: {
              color: "#fff",
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: "#fff",
            },
          },
          tooltip: {
            enabled: true,
            backgroundColor: "rgba(0, 192, 255, 0.8)",
            titleColor: "#fff",
            bodyColor: "#fff",
            borderColor: "#00c0ff",
            borderWidth: 1,
          },
        },
        interaction: {
          mode: "nearest",
          intersect: true,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
      },
    });
    return myChart
}