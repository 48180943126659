import "./style/index.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Login } from "./pages/Login";
import { Home } from "./pages/Home";
import { Header } from "./components/Header/Header";
import { HeaderLogin } from "./components/Header/HeaderLogin";
import { NavigationMenu } from "./components/Menu/NavigationMenu";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { deleteToken, loadToken } from "./store/features/adminLoginSlice";
import { Settings } from "./pages/Settings";
import { Resume } from "./pages/Resume";
import { Project } from "./pages/Project";
import { Blog } from "./pages/Blog";
import { Comments } from "./pages/Comments";
import {
  addAdminContact,
  addAdminProfile,
  addAdminSetting,
} from "./store/features/settings";
import { getAll, refreshAccessToken } from "./api/getAll";
import { setEducation, setSkills } from "./store/features/resume";

function App() {
  const token = useSelector((state) => state.login.accessToken);

  const isMenuOpened = useSelector((state) => state?.menu?.menuOpened);
  const dispatch = useDispatch();
  useEffect(() => {
    // Synchronise le token avec le store Redux au démarrage de l'application
    const localToken =
      JSON.parse(sessionStorage.getItem("token")) ||
      JSON.parse(localStorage.getItem("token"));
    if (localToken && !token) {
      dispatch(loadToken(localToken));
      // recuperer les données
      const loadData = async () => {
        const getData = await getAll(localToken);
        const status = getData.status;
        if (status === "201") {
          const datafetch = getData.data;
          if (datafetch) {
            const adminProfile = datafetch.admin_profile,
              adminContact = datafetch.admin_contact,
              adminSettings = datafetch.admin_settings,
              resume = datafetch.admin_resume;
            dispatch(setEducation(resume?.education));
            dispatch(setSkills(resume?.skills));

            dispatch(addAdminProfile(adminProfile));
            dispatch(addAdminContact(adminContact));
            dispatch(addAdminSetting(adminSettings));
          }
        } else if (
          status === "401" &&
          getData.error === "Token Expiré" &&
          localStorage.getItem("refreshToken")
        ) {
          const fetchRefreshToken = await refreshAccessToken();
          if (fetchRefreshToken.status === "201") {
            const token = fetchRefreshToken.accessToken;
            sessionStorage.setItem("token", JSON.stringify(token));

            const request = await getAll(token);
            if (request.status === "201") {
              const datafetch = request.data;
              if (datafetch) {
                const adminProfile = datafetch.admin_profile,
                  adminContact = datafetch.admin_contact,
                  adminSettings = datafetch.admin_settings,
                  resume = datafetch.admin_resume;
                dispatch(setEducation(resume?.education));
                dispatch(setSkills(resume?.skills));
                dispatch(addAdminProfile(adminProfile));
                dispatch(addAdminContact(adminContact));
                dispatch(addAdminSetting(adminSettings));
              }
            } else if (status === "401" && request.error === "Token Expiré") {
              // Deconnecter l'utilisateur car la session est expirée :2 semaines
              dispatch(deleteToken());
            }
          }
        } else {
          // deconnecter l'utilisateur car il n'avait pas coché remember me
          dispatch(deleteToken());
        }
      };

      loadData();
    }
  }, [token, dispatch]);

  return (
    <Router>
      {token ? <Header /> : <HeaderLogin />}
      <div className="layout d-flex ">
        {token && <NavigationMenu />}{" "}
        <section className={`content ${isMenuOpened ? "opened" : "closed"}`}>
          <Routes>
            <Route
              path="/login"
              element={!token ? <Login /> : <Navigate to="/" replace />}
            />
            <Route
              path="/"
              element={token ? <Home /> : <Navigate to="/login" replace />}
            />
            <Route
              path="/resume"
              element={token ? <Resume /> : <Navigate to="/login" replace />}
            ></Route>
            <Route
              path="/projects"
              element={token ? <Project /> : <Navigate to="/login" replace />}
            ></Route>
            <Route
              path="/settings"
              element={token ? <Settings /> : <Navigate to="/login" replace />}
            ></Route>
            <Route
              path="/blog"
              element={token ? <Blog /> : <Navigate to="/login" replace />}
            ></Route>
            <Route
              path="/comments"
              element={token ? <Comments /> : <Navigate to="/login" replace />}
            ></Route>
          </Routes>
        </section>
      </div>
    </Router>
  );
}

export default App;
