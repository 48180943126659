import { useEffect } from "react";
import { Card } from "../components/Project/Card";
import { fetchProjetData } from "../api/projectSection";
import { useDispatch, useSelector } from "react-redux";
import { setProjectData } from "../store/features/project";
import { ModalContent } from "../components/Project/Modal/ModalContent";
import { Button } from "../components/Project/Button";

export function Project() {
  const dispatch = useDispatch();
  useEffect(() => {
    const fecthData = async () => {
      const data = await fetchProjetData();
      if (data) {
        dispatch(setProjectData(data));
      }
    };

    fecthData();
  }, []);
  const projects = useSelector((state) => state?.project?.projectData) || [];

  return (
    <>
      <div className="ProjectSession d-flex">
        <h2>Projets</h2>
        <Button />
        <ul className="row cards gap-2">
          {projects?.map(
            ({ id, title, technologies, description, image, links }, index) => (
              <Card
                key={id}
                title={title}
                technologies={technologies}
                description={description}
                image={image}
                links={links}
              />
            )
          )}
        </ul>
      </div>
      <ModalContent />
    </>
  );
}
