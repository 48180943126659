import { useDispatch } from "react-redux";
import { FormatDate } from "./Date";
import { Stars } from "./Stars";
import { User } from "./User";
import { setModalFunction } from "../../store/features/comments";
import { openModal } from "../../store/features/modalSlice";

export function CommentCard({
  name,
  stars,
  userEmail,
  date,
  articleTitle,
  content,
  confirmed,
}) {
  const dispatch = useDispatch();
  const handeDeleteCard = (e) => {
    e.stopPropagation();
    dispatch(openModal());
    dispatch(setModalFunction("deleteCard"));
  };
  const handleClickCard = () => {
    dispatch(openModal());
    dispatch(
      setModalFunction(confirmed ? "invalideComment" : "confirmeComment")
    );
  };
  return (
    <li
      className={`card d-flex col-lg-3 col-md-4 col-12 ${
        confirmed ? "confirmed" : "notConfirmed"
      }`}
      onClick={() => handleClickCard()}
    >
      {confirmed && (
        <span className="confirmedIcon">
          <i className="fa-solid fa-circle-check"></i>
        </span>
      )}
      <span className="delete" onClick={(e) => handeDeleteCard(e)}>
        <i className="fa-solid fa-trash-can"></i>
      </span>

      <User name={name} userEmail={userEmail} />
      <Stars stars={stars} />
      <FormatDate date={date} />
      <h4> Article : {articleTitle} </h4>
      <p>{content}</p>
    </li>
  );
}
