import { useSelector } from "react-redux";
import { Picture } from "./Picture";
import { inputConfig } from "../../../data/dataProject";
import { Input } from "./Input";
import { CustomModal } from "../../Modal/Modal";
import { DeleteElement } from "./DeletetElement";

export function ModalContent() {
  const modalData = useSelector((state) => state.project.modalData);
  const inputArray = inputConfig(
    modalData?.id,
    modalData?.title,
    modalData?.technologies,
    modalData?.description,
    modalData?.links?.website,
    modalData?.links?.github
  );
  return (
    <CustomModal title={modalData?.modalTile}>
      {modalData ? (
        <form>
          <Picture image={modalData?.image} />
          {inputArray.map(({ name, label, type, value }, index) => (
            <div key={index} className="form-group">
              <Input
                key={index}
                name={name}
                label={label}
                value={value}
                type={type}
              />
            </div>
          ))}
        </form>
      ) : (
        <DeleteElement />
      )}
    </CustomModal>
  );
}
