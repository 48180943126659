import { createSlice } from "@reduxjs/toolkit";

const commentSlice = createSlice({
  name: "comment",
  initialState: {
    dataComment: null,
    confirmationFilter: "all",
    categoryFilter: "",
    dataButton: null,
    modalFunction: null,
  },
  reducers: {
    setDataComment: (state, actions) => {
      state.dataComment = actions.payload;
    },
    setModalFunction: (state, actions) => {
      state.modalFunction = actions.payload;
    },
    setDataButton: (state, actions) => {
      state.dataButton = actions.payload;
    },
    setconfirmationFilter: (state, actions) => {
      state.confirmationFilter = actions.payload;
    },
    setcategoryFilter: (state, actions) => {
      state.categoryFilter = actions.payload;
    },
  },
});
export const {
  setDataButton,
  setDataComment,
  setcategoryFilter,
  setconfirmationFilter,
  setModalFunction,
} = commentSlice.actions;
export default commentSlice.reducer;
