
function Card({ icon, title, description }) {
    return (
        <article className="card">
            <span className="card-icon">
                <i className={`fa-solid ${icon}`}></i>
            </span>
            <h4>{title}</h4>
            <p>{description}</p>
        </article>
    );
}

export function CardList({ data }) {
    return (
        <li className="card card-2">
            {data.map((item, index) => (
                <Card 
                    key={index} 
                    icon={item.icon} 
                    title={item.title} 
                    description={item.description} 
                />
            ))}
        </li>
    );
}
