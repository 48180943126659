import { LoadChart } from "../components/Chart/LoadChart";
import { Cards } from "../components/Home/Cards";
import { HeaderButtonList } from "../components/Home/HeaderBoutons";
import { CardList } from "../components/Home/HomeCard";
import { HomePicture } from "../components/Home/HomePicture";
import { buttonData, cardData } from "../data/cardHome";
export function Home() {
  return (
    <div className="homeSection">
      <h2 className="sr-only">Home</h2>
      <Cards />
      <div className="d-flex w-100 content-gaphique-cards">
        <div className="list-inline d-flex cards-left">
          <h3>Home section</h3>
          <HeaderButtonList buttons={buttonData} />
          <ul className="d-flex inner-home">
            <HomePicture />
            <CardList data={cardData} />
          </ul>
        </div>
      </div>
    </div>
  );
}
