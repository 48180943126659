import React from 'react';

function HeaderButton({ icon, label }) {
    return (
        <li className="card btn btn-outline">
            <span className="card-icon">
                <i className={`fa-solid ${icon}`}></i>
            </span>
            {label}
        </li>
    );
}

export function HeaderButtonList({ buttons }) {
    return (
        <ul className="d-flex header">
            {buttons.map((button, index) => (
                <HeaderButton key={index} icon={button.icon} label={button.label} />
            ))}
        </ul>
    );
}
