import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    projectData: null,
    modalData: null,
  },
  reducers: {
    setProjectData: (state, actions) => {
      state.projectData = actions.payload;
    },
    setModalData: (state, actions) => {
      state.modalData = actions.payload;
    },
  },
});

export const { setModalData, setProjectData } = projectSlice.actions;
export default projectSlice.reducer;
