import developpeuseImage from './../../assets/images/developpeuse_web.webp'

export function HomePicture(){
    return (
        <li className="card p-0 img">
            <span className="card-icon">
                <i className="fa-solid fa-pen-to-square"></i>
            </span>
            <img src={developpeuseImage} alt="developpeur web"/>
        </li>
    )
}