// import './style/LoginForm.css'
import { useState } from "react";
import { fetchLogin, isValidEmail } from "./../../api/loginAPI.js";
import { ForgotPassword } from "./ForgotPassword.jsx";
import { useDispatch } from "react-redux";
import {
  addRefreshToken,
  deleteToken,
  loadToken,
} from "../../store/features/adminLoginSlice.js";
import { useNavigate } from "react-router-dom";
import { getAll } from "../../api/getAll.js";
import {
  addAdminContact,
  addAdminProfile,
  addAdminSetting,
} from "../../store/features/settings.js";
import { setEducation, setSkills } from "../../store/features/resume.js";

export function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState("");
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tooglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      setError("Tous les champs sont requis");
    } else {
      if (!isValidEmail(email)) {
        setError("Erreur de syntaxe email.");
      } else {
        let accessToken;
        try {
          const response = await fetchLogin(email, password);
          const adminInfo = response.data;
          if (adminInfo) {
            accessToken = adminInfo.accessToken;
            const refreshToken = adminInfo.refreshToken;
            dispatch(loadToken(accessToken));

            rememberMe && dispatch(addRefreshToken(refreshToken));
            sessionStorage.setItem("token", JSON.stringify(accessToken));
            const request = await getAll(accessToken);
            if (request.status === "201") {
              const datafetch = request.data;
              if (datafetch) {
                const adminProfile = datafetch.admin_profile,
                  adminContact = datafetch.admin_contact,
                  adminSettings = datafetch.admin_settings,
                  resume = datafetch.admin_resume;
                dispatch(setEducation(resume?.education));
                dispatch(setSkills(resume?.skills));
                dispatch(addAdminProfile(adminProfile));
                dispatch(addAdminContact(adminContact));
                dispatch(addAdminSetting(adminSettings));
              }
            } else {
              setError(request.error);
              dispatch(deleteToken());
              return;
            }
            navigate("/");
          } else {
            setError("Impossible de vous identifier");
            dispatch(deleteToken());
          }
        } catch (error) {
          setError(
            "Une erreur s'est produite lors de la soumission du formulaire , réessayez plutard"
          );
        }
      }
    }
  };

  return (
    <main className="mainLogin">
      <form className="logInForm" onSubmit={(e) => formSubmit(e)}>
        <h2>Log in</h2>
        <fieldset>
          <label htmlFor="email">E-mail</label>
          <input
            type="email"
            name="email"
            id="email"
            className="input"
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="password">Mot de passe</label>
          <input
            type={!showPassword ? "password" : "text"}
            name="password"
            id="password"
            autoComplete="current-password"
            className="input"
            onChange={(e) => setPassword(e.target.value)}
          />
          <i
            className={`fa-solid ${showPassword ? "fa-eye" : "fa-eye-slash"} `}
            onClick={() => tooglePasswordVisibility()}
          ></i>
        </fieldset>
        <fieldset className="remember">
          <input
            type="checkbox"
            id="remenberMe"
            name="remenberMe"
            autoComplete="remenberMe"
            checked={rememberMe}
            onChange={() => {
              setRememberMe((prev) => !prev);
            }}
          />
          <label htmlFor="remenberMe">Se souvenir de moi</label>
        </fieldset>
        {error && <p>{error}</p>}
        <button className="button login">Se connecter</button>

        <ForgotPassword error={error} />
      </form>
    </main>
  );
}
