import { useDispatch } from "react-redux";
import { setModalData } from "../../store/features/project";
import { openModal } from "../../store/features/modalSlice";

export function Card({ id, title, technologies, description, image, links }) {
  const element = {
    modalTile: "Modifiez le projet",
    id,
    title,
    technologies,
    description,
    image,
    links,
  };
  const dispatch = useDispatch();
  const handleClikCard = () => {
    dispatch(setModalData(element));
    dispatch(openModal());
  };
  const handleDeleteElement = (e) => {
    e.stopPropagation();
    dispatch(setModalData(null));
    dispatch(openModal());
  };
  return (
    <li
      className="card d-flex col-lg-3 col-md-5 col-11 "
      onClick={() => handleClikCard()}
    >
      <span className="icon" onClick={(e) => handleDeleteElement(e)}>
        <i className="fa-solid fa-trash-can"></i>
      </span>
      <div className="card-picture">
        <img src={image} alt={`${title} - cover`} />
      </div>
      <div className="card-body">
        <h3>{title}</h3>
        <h4>{technologies}</h4>
        <p>{description}</p>
        <span className="links d-flex">
          <a href={links?.website}> Website </a>
          <a href={links?.github}> github </a>
        </span>
      </div>
    </li>
  );
}
