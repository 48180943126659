import { Card } from "./Card";
import { cardHome } from "./../../data/cardHome";
export function Cards() {
  return (
    <ul className="d-flex list-inline w-100 cards-top">
      {cardHome.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          badgeCount={card.count}
          message={card.mainText}
          actions={card.actions}
          newComment={card.new}
        />
      ))}
    </ul>
  );
}
