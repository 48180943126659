export function inputConfig(
  id,
  title,
  technologies,
  description,
  website,
  github
) {
  const dataResume = {
    // Configuration pour l'adresse et le contact
    education: [
      {
        name: "title",
        label: "Titre de la formation",
        type: "input",
        value: title,
      },
      {
        name: "technologies",
        label: "Technologies",
        type: "technologies",
        value: technologies,
      },
      {
        name: "description",
        label: "Description",
        type: "textarea",
        value: description,
      },
      {
        name: "Website",
        label: "Lien du site",
        type: "text",
        value: website,
      },
      {
        name: "github",
        label: "Lien github",
        type: "text",
        value: github,
      },
    ],
  };
  return dataResume.education;
}
