import { useSelector } from "react-redux";
import { CustomModal } from "../../Modal/Modal";

export function ModalContent() {
  const modalFunction = useSelector((state) => state?.comment?.modalFunction);
  return (
    <CustomModal title={""} isSent={false}>
      <p className="modalFunction">
        {modalFunction === "confirmeComment"
          ? "Voulez vous valider ce avis?"
          : modalFunction === "invalideComment"
          ? "Voulez vous invalider cet avis ?"
          : " Voulez vous vraiment supprimer cet avis?"}
      </p>
    </CustomModal>
  );
}
