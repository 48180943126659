import { Link } from "react-router-dom";

export function Card({ title, badgeCount, message, actions, newComment }) {
  return (
    <li className="card p-2">
      <h3>
        {title}
        <span className={`badge ${newComment ? "bg-danger" : ""}`}>
          {badgeCount}
        </span>
      </h3>
      {message && <p>{message}</p>}
      {actions.map((action, index) => (
        <p key={index}>
          <Link to={action.url}>
            <i className={action.icon}></i>
            {action.text}
          </Link>
        </p>
      ))}
    </li>
  );
}
