export function isValidEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}

export async function fetchLogin(email, password) {
  const userData = { email, password };
  const request = await fetch("https://api.rouguiz.com/v1/login", {
    method: "POST",
    body: JSON.stringify(userData),
  });
  const response = await request.json();
  return response;
}

export async function fetchForgotPassword(email) {
  const request = await fetch("https://api.rouguiz.com/v1/forgot-password", {
    method: "POST",
    body: JSON.stringify({ email }),
  });

  return request.json();
}
